.magic-banners-usage {
  &.states {
    height: 100%;
  }

  .images-info {
    color: #757575;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .galleria-action-btn {
    width: fit-content;
    height: fit-content;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .images-count {
    color: #757575;

    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .p-galleria.p-component.p-galleria-thumbnails-bottom {
    .p-galleria-content {
      gap: 0.75rem;

      .p-galleria-thumbnail-wrapper {
        .p-galleria-thumbnail-container {
          background: transparent;

          .p-galleria-thumbnail-items {
            gap: 0.75px;
            justify-content: space-between;
          }

          .p-galleria-thumbnail-item {
            cursor: pointer;
            height: 92px;
            width: 112px;
            flex: none;
            padding: 0.5rem;
            background: #F6F6F6;
            border-radius: 8px;
            border: 1px solid #F6F6F6;

            &.p-galleria-thumbnail-item-current {
              border-color: #007B94;
            }

            .gallery-image-preview-container {
              width: 100%;
              height: 74px;
            }

            .gallery-preview-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .image-preview-container {
    width: 100%;
    height: 548px;
    padding: 2rem 3rem;
    border-radius: 10px;
    background: #F6F6F6;

    .image-preview {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .not-success-state-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #F6F6F6;

    .not-success-state-title, .not-success-state-description {
      color: var(--Neutrals-800, #454545);
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
    }

    .not-success-state-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .not-success-state-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}