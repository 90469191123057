.creative-uploader {
  width: 226px;

  &-title {
    color: #262626;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .upload-photo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 158px;
    cursor: pointer;
    border: 1px solid #E7E7E7;
    background: #F6F6F6;

    .uploader-mask {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .68);
      opacity: 0;
      transition: opacity .2s;
    }

    &:hover {
      .uploader-mask {
        opacity: 1;
      }
    }

    .creative-uploader-logo {
      height: 105px;
    }

    .uploaded-file {
      width: 100%;
      height: 100%;
    }

    .uploaded-file {
      object-fit: contain;
    }
  }
}