@import 'tokens.scss';

.CampaignDashboard {
  height: 100%;
  &__main_div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
  }
  &__newCampaign_btn {
    width: 202px;
    height: 48px;
    background: #fcd917;
    border-radius: 10px;
    border: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2b1f0a !important;
  }
  &__left_main_col {
    width: 63%;
    display: flex;
    flex-direction: column;
  }
  &__right_main_col {
    display: flex;
    flex-direction: column;
    width: 34%;
  }
  &__CampaignPerformance {
    width: 100%;
    border-radius: 20px;
    background: #ffffff;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  &__left_col_child_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__Saved_audiences {
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
    background: #ffffff;
  }
  &__ProgressCampaign {
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
    background: #ffffff;
    margin-top: 2rem;
  }
  &__graphdata_campaign {
    width: 48.5%;
    border-radius: 20px;
    padding: 1rem;
    background: #ffffff;
  }
  &__graphdata_finshed {
    width: 48.5%;
    border-radius: 20px;
    padding: 1rem;
    background: #ffffff;
  }
  &__body {
    display: flex;
    background: #f8f8f8;
    // height: 100%;
    border-radius: 18px;
    height: calc(100% - 60px);
    // margin-bottom: 54px;
    // position: absolute;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // top: 0;

    @media (max-width: 1050px) {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    @media screen and (max-width: 699px) {
      align-items: center;
      height: auto;
    }
  }

  .PageHeader {
    background-color: inherit;
    padding-inline: 0;
    margin-bottom: 1rem;
  }
  &__left-column {
    width: 60%;
    margin: 1rem;

    @media (max-width: 1050px) {
      width: 95%;
    }
    @media (max-width: 699px) {
      margin-top: 0;
    }

    .box_container {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      flex-grow: 1;

      @media (max-width: 699px) {
        display: block;
      }
    }
  }
  &__right-column {
    width: 40%;
    margin: 1rem;

    @media (max-width: 1050px) {
      width: 95%;
    }

    @media screen and (max-width: 699px) {
      margin-bottom: 0;
    }
  }
  &__campaign-performance {
    margin-top: 1rem;
    height: 100%;
  }
  &__faded-box {
    margin: 1rem;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darkgray;
  }
  &__footer_container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .Section {
    background-color: #ffffff;

    @media (max-width: 699px) {
      margin-bottom: 1rem;
    }
  }
}
