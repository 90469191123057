.edit-creative-modal.Modal__content {
  width:825px !important;
  position: relative;
  padding:40px 32px;
  display: flex;
  flex-direction: column;
  gap:32px;
  .close_cross {
    border: none;
    padding: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    z-index: 99;
  }
    .url_wrapper{
      margin-bottom:8px
    }
    .link_input_field_wrapper{width:100% !important}
    .link_input_field:not(:focus):hover {
      border: 1px solid #ffab03 !important;
    }

    .link_input_field:focus {
      border: 1px solid #ffab03;
    }

    .link_input_field {
      padding: 13px 0 13px 100px;
      border: 1px solid #aab2b5;
      border-radius: 10px !important;
      background: none !important;
      width: 100%;
      font-size: 17px;

      @media screen and (min-width: 3200px) {
        padding: 25px 0 25px 165px;
        width: 700px !important;
        font-size: 32px !important;
      }

      @media (max-width: 699px) {
        width: 100% !important;
      }
      @media screen and (max-width: 500px) {
        padding-left: 83px !important;
        padding-top: 5px !important;
      }
    }
  .creative_images {
    margin-block: 32px;
    flex-wrap: wrap;
    .creative_content {
      border-radius: 8px;
      border: 1px solid #EAECF0;
      background: #FFF;
      display: flex;
      width: 231px;
      padding: 16px 24px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .creative_title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 183px;
        color: #262626;
        font-size: 18px;
        font-weight: 600;
      }
      .creative_img_wrapper {
        .creative_img {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 176px;
          cursor: pointer;
          border: 1px solid #E7E7E7;
          background: #F6F6F6;
          border-radius: 8px;
        }

        .creative_action {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .68);
          opacity: 0;
          transition: opacity .2s;
        }

        &:hover {
          .creative_action {
            opacity: 1;
          }
        }

        .action {
          color: #fff;
          background:transparent;
          border:none;
          cursor:pointer;
        }

        .creative_image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }


    }
  }
}